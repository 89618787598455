// import { cloudfrontImageLoader } from '@lib/utils/cloudfrontImageLoader'
import Image, { ImageProps, StaticImageData } from 'next/image'
import { FC, useMemo } from 'react'

export interface BaseImageProps extends Omit<ImageProps, 'src'> {
  src: string | StaticImageData | null | undefined
  fallbackImageSrc: string
  isS3Hosted?: boolean
}

const getImageSource = (
  src: string | StaticImageData | null | undefined,
  fallbackImageSrc: string
): {
  source: string | StaticImageData
  isStaticAsset: boolean
} => {
  if (!src) {
    return { source: fallbackImageSrc, isStaticAsset: true }
  }
  if (typeof src === 'string') {
    const urlWithHost = src.startsWith('//') ? `https:${src}` : src
    return { source: urlWithHost, isStaticAsset: false }
  }
  return { source: src, isStaticAsset: false }
}

export const BaseImage: FC<BaseImageProps> = ({ fallbackImageSrc, src, alt, ...props }) => {
  const { source } = useMemo(() => getImageSource(src, fallbackImageSrc), [src, fallbackImageSrc])

  return (
    <>
      <Image
        {...props}
        // {...(isS3Hosted &&
        //   !isStaticAsset && {
        //     loader: cloudfrontImageLoader
        //   })}
        alt={alt || ''}
        src={source}
        onError={() => {
          if (source !== fallbackImageSrc) {
            BaseImage({ ...props, src: fallbackImageSrc, fallbackImageSrc, alt })
          }
        }}
      />
    </>
  )
}
